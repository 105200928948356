<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>

<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>
    <div class="row">
      <div class="col-md-12 text-left">
        Mises à jours
        <button @click="save()" class="btn btn-success" style="float:right"><span v-if="save_loading">En cours...</span><span v-if="!save_loading">Sauvegarder</span></button>
      </div>
    </div>
    
  </v-card-title>
<table class="table table-bordered">
  <thead style="width:100%">
    <tr><th class="text-center" style="vertical-align:middle">Catégorie</th><th  style="vertical-align:middle" class="text-center">Code Barre</th><th style="vertical-align:middle" class="text-center">Nom</th><th style="vertical-align:middle" class="text-center">Coût <small v-if="rate_id == 1" style="color:green;font-weight:bold">(HTG)</small> <small v-if='rate_id == 2' style="color:green;font-weight:bold">USD</small></th><th style="vertical-align:middle" class="text-center">Prix <small v-if="rate_id == 1" style="color:green;font-weight:bold">(HTG)</small> <small v-if='rate_id == 2' style="color:green;font-weight:bold">USD</small></th><th style="vertical-align:middle" v-if="store.min_price"  class="text-center">Prix Min <small v-if="rate_id == 1" style="color:green;font-weight:bold">(HTG)</small> <small v-if='rate_id == 2' style="color:green;font-weight:bold">USD</small></th></tr>
  </thead>
  <tbody >
  <tr  v-for="product in products" :key="product.product_id" :class="product.class">
    <td class="text-center" style="vertical-align:middle;padding:0px;height:50px;">
      <select v-model="product.category_id" style="width:100%;height:100%;text-align:center">
        <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
      </select></td>
    <td class="text-center" style="vertical-align:middle;padding:0px;height:50px;"><input style="border-radius: 0px!important;width:100%;height: 100%;text-align: center;vertical-align: middle"
            solo
            v-model=product.barcode
          ></td>
    <td class="text-center" style="vertical-align:middle;padding:0px;height:50px;"><input style="border-radius: 0px;width:100%;height: 100%;text-align: center;vertical-align: middle"
            solo
            v-model=product.name
          ></td>
    <td style="vertical-align:middle;text-align: center;padding:0px;height:50px;"><input style="border-radius: 0px;width:100%;height: 100%;text-align: center;vertical-align: middle"
            solo
            v-model=product.cost
            type="number"
          ></td>
    <td style="vertical-align:middle;text-align: center;padding:0px;height:50px;"><input style="border-radius: 0px;width:100%;height: 100%;text-align: center;vertical-align: middle"
            solo
            v-model=product.price
            type="number"
          ></td>
    <td style="vertical-align:middle;text-align: center;padding:0px;height:50px;" v-if="store.min_price"><input style="border-radius: 0px;width:100%;height: 100%;text-align: center;vertical-align: middle"
            solo
            v-model=product.min_price
            type="number"
          ></td>

        <!--   <td style="vertical-align:middle;text-align: center;padding:0px;height:50px;"><input style="border-radius: 0px;width:100%;height: 100%;text-align: center;vertical-align: middle"
            solo
            v-model=product.min_price
            type="number"
          ></td> -->
  </tr>
</tbody>
</table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import numeral from 'numeral'

export default {
  name: 'CatalogView',
  methods: {
    getCategories: function(){
      var self = this;
      this.$axios.get('/categories?orderasc=name')
      .then(function (response) {
        self.categories = response.data
      })
    },
    save:function(){
      var url = '/store-products/modify'
      var self = this
      this.save_loading = true
      this.setProducts();
      this.$axios.post(url+"?data="+encodeURIComponent(JSON.stringify(self.products)))
      .then(response => {
        console.log(response)
        self.dialog = false
        self.loading = false
        this.save_loading = false
      }).catch(function(error){
        alert(error)
      })
    },
    load: function(){
      var self = this;
      this.$axios.get('/products?orderasc=name')
        .then(function (response) {
          var object = {}
          self.rows = response.data
          for (var i = 0; i < self.rows.length; i++) {
            object = {id: self.rows[i].id, price: self.rows[i].price, cost: self.rows[i].cost, status:2, category_id: self.rows[i].category_id, name:self.rows[i].name, barcode: self.rows[i].barcode, min_price: self.rows[i].min_price }
            self.products.push(object)
          }
          
          
        })
    },
    setProducts: function(){
      for (var i = this.rows.length - 1; i >= 0; i--) {
        this.rows[i].status = 2
      }
    },
    formatNumber: function(cost, rate){
      return numeral(cost).format('0,0.00') + " " + rate
    },
    getStores: function(){
      this.authentication = JSON.parse(localStorage.authentication)
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      this.$axios.get('/stores?orderasc=name&include=store_rates&business_id='+this.business_id)
        .then(function (response) {
          self.store = response.data[0]
          self.rate_id = self.store.rate_id
          for (var j = self.store.store_rates.length - 1; j >= 0; j--){
              if(self.store.store_rates[j].rate_id == 2){
                self.rate = self.store.store_rates[j].sales_rate
              }
            }
          self.enable = true          
        })
    },
  },
  mounted(){
    this.getStores()
    this.getCategories()
    this.load()
  },
  data: () => ({
      categories: [],
      store: [],
      rows: [],
      products:[],
      rate:1,
      rate_id:1,
      items: [
        {
          text: 'Mises à jours',
          disabled: false,
          href: '/catalog',
        }
      ],
      fluid: true,
      save_loading:false,
      business_id: '',
      store_id: "",
      enable:false,
      headers: [
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'image_url',
          },
          { text: 'NOM', value: 'name' },
          { text: 'CATEGORIE', value: 'category.name' },
          { text: 'COUT', value: 'cost' },
          { text: 'PRIX', value: 'price' },
          { text: 'PRIX MIN', value: 'min_price' },
        ],
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .v-application--wrap .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

tbody {
    display: block;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
}
table {
    width: 400px;
}
.no_details, .no_details td{
  background: #f3f3f3!important;
}

.edit_element, .edit_element td{
  background: #fff!important;
}


.delete_element, .delete_element td{
  background: #f2dede!important;
}

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .v-dialog button.v-btn{
    margin-right:8px!important;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:18px;
  }

   .v-application .v-dialog .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:0px!important;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }c
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>